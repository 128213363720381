
  import { Component, Prop } from 'vue-property-decorator'
  import DefaultLayout from '@/layouts/Index.vue'

@Component({
  components: {
    AppBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar.vue'
        ),
    ViewFooter: () => null,
  },
})
  export default class Index extends DefaultLayout {
  @Prop({ type: Number }) uid?: number
  @Prop({ type: String }) returnTo?: string

  get bar () {
    const { uid, returnTo } = this
    return { uid, returnTo }
  }
  }
